<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-v-ba36b5c4=""
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.37211 1.92885C8.78006 1.3368 7.96751 1.01911 7.13089 1.05257L2.56657 1.23514C2.04567 1.25598 1.62817 1.67348 1.60734 2.19438L1.42476 6.7587C1.3913 7.59532 1.70899 8.40787 2.30105 8.99992L6.59398 13.2929C7.76555 14.4644 9.66504 14.4644 10.8366 13.2929L13.665 10.4644C14.8366 9.29285 14.8366 7.39336 13.665 6.22178L9.37211 1.92885ZM3.6353 3.98056C4.04952 3.98056 4.3853 3.64478 4.3853 3.23056C4.3853 2.81635 4.04952 2.48056 3.6353 2.48056C3.22109 2.48056 2.8853 2.81635 2.8853 3.23056C2.8853 3.64478 3.22109 3.98056 3.6353 3.98056Z"
      fill="url(#paint0_linear_885_3969)"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_885_3969"
        x1="7.98304"
        y1="1.05017"
        x2="7.98304"
        y2="14.1715"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="stop_color1"></stop>
        <stop offset="1" :stop-color="stop_color2"></stop>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 20,
    },
    stop_color1: {
      type: String,
      default: "black",
    },
    stop_color2: {
      type: String,
      default: "grey",
    },
  },
};
</script>
